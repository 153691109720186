import React from "react";
import Icon from "@ant-design/icons";

const warningTriangleSvg = () => (
  <svg
    width="100%"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 17.7368V19.8421" stroke="currentColor" strokeWidth="2" />
    <path d="M12 10.3684V16.6842" stroke="currentColor" strokeWidth="2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3L2 23H22L12 3Z"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
);

const WarningTriangleIcon = (props: any) => (
  <Icon component={warningTriangleSvg} {...props} />
);

export default WarningTriangleIcon;
