import React from "react";
import Icon from "@ant-design/icons";

const vehicle2Svg = () => (
  <svg
    width="100%"
    height="16"
    viewBox="0 0 66 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M59.5371 43.4669C63.5446 42.834 63.8733 37.5783 63.8733 36.7344V27.2973C63.8733 24.986 63.9919 23.7967 63.3256 22.8281C62.6592 21.8594 59.2815 19.0494 59.2815 19.0494L53.1014 5.83363C51.1022 1 44.0914 1 44.0914 1H21.8903C21.8903 1 14.8886 1 12.8803 5.83363L6.70015 19.0494C6.70015 19.0494 3.3134 21.8594 2.65613 22.8281C1.99886 23.7967 2.10841 24.986 2.10841 27.2973V36.7344C2.10841 37.5783 2.44617 42.834 6.44455 43.4669H59.528H59.5371Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.9967 43.4092H5.69531V52.9997H16.9967V43.4092Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M60.295 43.4092H48.9937V52.9997H60.295V43.4092Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.1083 32.2937C15.2561 32.2937 16.9971 30.4645 16.9971 28.2081C16.9971 25.9517 15.2561 24.1226 13.1083 24.1226C10.9606 24.1226 9.21948 25.9517 9.21948 28.2081C9.21948 30.4645 10.9606 32.2937 13.1083 32.2937Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M52.8822 32.2937C55.03 32.2937 56.7711 30.4645 56.7711 28.2081C56.7711 25.9517 55.03 24.1226 52.8822 24.1226C50.7345 24.1226 48.9934 25.9517 48.9934 28.2081C48.9934 30.4645 50.7345 32.2937 52.8822 32.2937Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.42114 36.9453H18.1748"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M47.8245 36.9453H58.5781"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.3235 29.3501H42.6672"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.0288 34.5767H38.96"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.1326 11.5975L3.98895 11.2139C3.98895 11.2139 0.373981 11.9715 1.09515 16.4407L6.54499 19.0493"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M55.8679 11.5975L62.0115 11.2139C62.0115 11.2139 65.6265 11.9715 64.9053 16.4407L59.4555 19.0493"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.68677 20.0468L18.7043 18.4355H47.0855L60.103 20.0468"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Vehicle2Icon = (props: any) => (
  <Icon component={vehicle2Svg} {...props} />
);

export default Vehicle2Icon;
